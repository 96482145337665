function createMobileNavigation() {
    const divToAppendTo = document.getElementById('main-header').getElementsByClassName('aem-Grid')[0];

    divToAppendTo.insertAdjacentHTML(
        "afterbegin",
        `<input class="u-show-mobile-tablet-only" type="checkbox" /> 
                       <div class="icon-open u-show-mobile-tablet-only"></div>
                       <div class="icon-close u-show-mobile-tablet-only"></div>
                  `);


}

function executeOnScrollBehavior () {
    const stickyHeader = document.getElementById('main-header');
    const stickyHeaderEmptyDiv = document.createElement('div');
    stickyHeaderEmptyDiv.setAttribute("id", "stickyHeaderEmptyDiv");
    stickyHeader.insertAdjacentElement("afterend", stickyHeaderEmptyDiv);
    const headerOffset = stickyHeader ? stickyHeader.getBoundingClientRect().top : 0;

    window.onscroll = function () {
        const bodyScrollTop = document.documentElement.scrollTop || document.body.scrollTop;

        if (stickyHeader && stickyHeaderEmptyDiv) {
            if (bodyScrollTop > headerOffset) {
                stickyHeader.classList.add('header--fixed');
                stickyHeader.style.position = 'fixed';
                stickyHeaderEmptyDiv.style.height = stickyHeader.offsetHeight.toString() + 'px';
            } else {
                stickyHeader.classList.remove('header--fixed');
                stickyHeader.style.position = 'relative';
                stickyHeaderEmptyDiv.style.height = '0';
            }
        }
    };
}


createMobileNavigation();
executeOnScrollBehavior();
